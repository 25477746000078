<template>
    <div class="examine_status_container">
        <!-- 预约成功 -->
        <div class="content">
            <div class="title">
                <van-icon
                    name="passed"
                    color="rgb(13, 190, 13)"
                    size="50px"
                />
                <span>预约成功</span>
            </div>
            <div class="info">
                <van-cell
                    title="体检人"
                    value="刘伟杰"
                    :border="false"
                />
                <van-cell
                    title="体检套餐"
                    value="驾驶员体检"
                    :border="false"
                />
                <van-cell
                    title="体检时间"
                    value="2021-04-21"
                    :border="false"
                />
                <van-cell
                    title="体检费"
                    value="30.00元"
                    :border="false"
                />
            </div>
            <div class="btn">
                <van-button
                    type="info"
                    @click="navHome"
                >返&nbsp;回&nbsp;首&nbsp;页</van-button>
                <van-button
                    type="primary"
                    @click="navRecord"
                >查&nbsp;看&nbsp;记&nbsp;录</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Status",
  data() {
    return {};
  },
  created() {},
  methods: {
    navHome() {
      this.$router.push({
        path: "/"
      });
    },
    navRecord() {
      this.$router.push({
        path: "/me/examine"
      });
    }
  }
};
</script>

<style scope>
.examine_status_container {
  padding-bottom: 60px;
}
.examine_status_container .content {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border: 1px solid #f5f5f5;
  box-shadow: darkgrey 10px 10px 30px 5px;
}
.examine_status_container .content .title {
  position: relative;
  height: 180px;
  font-size: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 120px;
}
.examine_status_container .content .info .van-cell__title,
.examine_status_container .content .info .van-cell__value {
  font-size: 40px;
  line-height: 70px;
}
.examine_status_container .content .info .van-cell__value {
  flex: 2 !important;
}
.examine_status_container .content .btn {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 0 40px;
}
.examine_status_container .content .btn .van-button {
  font-size: 36px;
}
.examine_status_container .content .btn .refresh {
  width: 100%;
}
</style>